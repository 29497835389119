import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { db } from '../firebase';

const SupervisorDetails = () => {
  const { id } = useParams();
  const [supervisor, setSupervisor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSupervisor = async () => {
      try {
        const supervisorRef = ref(db, `supervisors/${id}`);
        const snapshot = await get(supervisorRef);
        if (snapshot.exists()) {
          setSupervisor({ id, ...snapshot.val() });
        } else {
          setError("Supervisor not found");
        }
      } catch (error) {
        console.error("Error fetching supervisor:", error);
        setError("Failed to fetch supervisor details");
      } finally {
        setLoading(false);
      }
    };

    fetchSupervisor();
  }, [id]);

  if (loading) return <p>Loading supervisor details...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!supervisor) return <p>No supervisor data available.</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Supervisor Details</h1>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="bg-blue-500 text-white px-4 py-2">
          <h2 className="text-2xl font-bold">Event Management Supervisor ID</h2>
        </div>
        <div className="p-4 grid grid-cols-2 gap-4">
          <div>
            <img src={`https://api.dicebear.com/6.x/initials/svg?seed=${supervisor.name}`} alt="Supervisor Avatar" className="w-32 h-32 rounded-full mx-auto" />
          </div>
          <div>
            <p><strong>ID:</strong> {supervisor.id}</p>
            <p><strong>Name:</strong> {supervisor.name}</p>
            <p><strong>Email:</strong> {supervisor.email}</p>
            <p><strong>Phone:</strong> {supervisor.phone}</p>
          </div>
          <div className="col-span-2">
            <p><strong>Address:</strong> {supervisor.address}</p>
            <p><strong>Date of Birth:</strong> {supervisor.dateOfBirth}</p>
            <p><strong>Hire Date:</strong> {supervisor.hireDate}</p>
            <p><strong>Certifications:</strong> {supervisor.certifications}</p>
            <p><strong>Emergency Contact:</strong> {supervisor.emergencyContact}</p>
          </div>
        </div>
        <div className="bg-gray-100 px-4 py-3">
          <h3 className="font-bold">Notes</h3>
          <p>{supervisor.notes}</p>
        </div>
      </div>
      <div className="mt-4">
        <Link to={`/edit-supervisor/${id}`} className="bg-yellow-500 text-white px-4 py-2 rounded mr-2">
          Edit
        </Link>
        <Link to="/supervisors" className="bg-gray-500 text-white px-4 py-2 rounded">
          Back to List
        </Link>
      </div>
    </div>
  );
};

export default SupervisorDetails;