import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Cell, ComposedChart, Line, CartesianGrid } from 'recharts';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

const Dashboard = () => {
  const navigate = useNavigate();
  const [greeting, setGreeting] = useState('');
  const [emoji, setEmoji] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  useEffect(() => {
    const updateGreeting = () => {
      const hour = new Date().getHours();
      let greetingText = '';
      let greetingEmoji = '';

      if (hour >= 5 && hour < 12) {
        greetingText = 'Good Morning';
        greetingEmoji = '🌅';
      } else if (hour >= 12 && hour < 18) {
        greetingText = 'Good Afternoon';
        greetingEmoji = '☀️';
      } else if (hour >= 18 && hour < 22) {
        greetingText = 'Good Evening';
        greetingEmoji = '🌇';
      } else {
        greetingText = 'Good Night';
        greetingEmoji = '🌙';
      }

      setGreeting(greetingText);
      setEmoji(greetingEmoji);
    };

    updateGreeting();
    const intervalId = setInterval(updateGreeting, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const cardData = [
    { title: 'Clients', count: 150, link: '/clients' },
    { title: 'Events', count: 25, link: '/events' },
    { title: 'Products', count: 75, link: '/products' },
    { title: 'Equipments', count: 100, link: '/equipments' },
  ];

  const pieChartData = [
    { name: 'Wedding', value: 400 },
    { name: 'Corporate', value: 300 },
    { name: 'Birthday', value: 200 },
    { name: 'Anniversary', value: 100 },
  ];

  const barChartData = [
    { name: 'Jan', Wedding: 4000, Corporate: 2400, Birthday: 2400 },
    { name: 'Feb', Wedding: 3000, Corporate: 1398, Birthday: 2210 },
    { name: 'Mar', Wedding: 2000, Corporate: 9800, Birthday: 2290 },
    { name: 'Apr', Wedding: 2780, Corporate: 3908, Birthday: 2000 },
    { name: 'May', Wedding: 1890, Corporate: 4800, Birthday: 2181 },
    { name: 'Jun', Wedding: 2390, Corporate: 3800, Birthday: 2500 },
  ];

  const paretoChartData = [
    { name: 'Product A', sales: 120, cumulativePercentage: 25 },
    { name: 'Product B', sales: 100, cumulativePercentage: 45 },
    { name: 'Product C', sales: 80, cumulativePercentage: 60 },
    { name: 'Product D', sales: 70, cumulativePercentage: 75 },
    { name: 'Product E', sales: 60, cumulativePercentage: 87 },
    { name: 'Others', sales: 70, cumulativePercentage: 100 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const exportToPDF = async () => {
    const input = document.getElementById('dashboard-content');
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    pdf.addImage(imgData, 'PNG', 0, 0);
    pdf.save(`Dashboard_Report_${startDate}_to_${endDate}.pdf`);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <h1 className="text-xl font-bold text-gray-800">Event Management Dashboard</h1>
              </div>
            </div>
            <div className="flex items-center">
              <button
                onClick={handleSignOut}
                className="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="py-10">
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 py-8 sm:px-0" id="dashboard-content">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                {greeting} {emoji}
              </h2>

              <div className="mb-4">
                <label className="mr-2">Start Date:</label>
                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="mr-4" />
                <label className="mr-2">End Date:</label>
                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="mr-4" />
                <button onClick={exportToPDF} className="px-4 py-2 bg-blue-500 text-white rounded">Export PDF</button>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
                {cardData.map((card, index) => (
                  <Link to={card.link} key={index} className="block">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">{card.title}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{card.count}</dd>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Total Sales by Event</h3>
                    <div className="mt-5">
                      <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                          <Pie
                            data={pieChartData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            {pieChartData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>

                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Total Sales by Event Over Time</h3>
                    <div className="mt-5">
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={barChartData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis 
                            dataKey="name" 
                            label={{ value: 'Month', position: 'insideBottom', offset: -5 }}
                            tick={{fontSize: 12}}
                          />
                          <YAxis 
                            label={{ value: 'Sales ($)', angle: -90, position: 'insideLeft', offset: 15 }}
                            tick={{fontSize: 12}}
                          />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="Wedding" fill="#0088FE" />
                          <Bar dataKey="Corporate" fill="#00C49F" />
                          <Bar dataKey="Birthday" fill="#FFBB28" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white overflow-hidden shadow rounded-lg mb-8">
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Product Sales Over Time (Pareto Chart)</h3>
                  <div className="mt-5">
                    <ResponsiveContainer width="100%" height={300}>
                      <ComposedChart data={paretoChartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="name" 
                          label={{ value: 'Product', position: 'insideBottom', offset: -5 }}
                          tick={{fontSize: 12}}
                        />
                        <YAxis 
                          yAxisId="left" 
                          orientation="left" 
                          stroke="#8884d8"
                          label={{ value: 'Sales', angle: -90, position: 'insideLeft', offset: 15 }}
                          tick={{fontSize: 12}}
                        />
                        <YAxis 
                          yAxisId="right" 
                          orientation="right" 
                          stroke="#82ca9d"
                          label={{ value: 'Cumulative %', angle: 90, position: 'insideRight', offset: 15 }}
                          tick={{fontSize: 12}}
                        />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="sales" fill="#8884d8" />
                        <Line yAxisId="right" type="monotone" dataKey="cumulativePercentage" stroke="#82ca9d" />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;