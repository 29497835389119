import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ref, query, orderByChild, get, remove } from 'firebase/database';
import { db } from '../firebase';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-2xl">&times;</button>
        </div>
        {children}
      </div>
    </div>
  );
};

const ProductCard = ({ product, isSelected, onSelect, onViewDetails }) => (
  <div className={`bg-white rounded-lg shadow-md p-4 flex flex-col justify-between ${isSelected ? 'border-2 border-blue-500' : ''}`}>
    <div className="flex justify-between items-start">
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => onSelect(product.id)}
        className="form-checkbox h-5 w-5 text-blue-600"
      />
    </div>
    <div className="flex-grow flex flex-col">
      <div className="relative pb-[100%] mb-4">
        <img 
          src={product.imageUrl} 
          alt={product.name} 
          className="absolute inset-0 w-full h-full object-contain rounded cursor-pointer"
          onClick={() => onViewDetails(product)}
        />
      </div>
      <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
      <p className="text-gray-600 mb-2">${product.price.toFixed(2)}</p>
      <p className="text-sm text-gray-500 mb-2">Category: {product.category}</p>
      <p className="text-sm text-gray-500 mb-4">Stock: {product.stock}</p>
    </div>
    <div className="flex justify-between">
      <button onClick={() => onViewDetails(product)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 text-sm">
        View Details
      </button>
      <Link to={`/edit-product/${product.id}`} className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 text-sm">
        Edit
      </Link>
    </div>
  </div>
);

const ProductDetailsModal = ({ isOpen, onClose, product }) => {
  if (!product) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-2xl font-bold mb-4">{product.name}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="relative pb-[100%]">
          <img 
            src={product.imageUrl} 
            alt={product.name} 
            className="absolute inset-0 w-full h-full object-contain rounded"
          />
        </div>
        <div>
          <p className="text-lg font-semibold mb-2">${product.price.toFixed(2)}</p>
          <p className="mb-2"><strong>Category:</strong> {product.category}</p>
          <p className="mb-2"><strong>Stock:</strong> {product.stock}</p>
          <p className="mb-2"><strong>SKU:</strong> {product.sku}</p>
          <p className="mb-2"><strong>Weight:</strong> {product.weight} kg</p>
          <p className="mb-2"><strong>Dimensions:</strong> {product.dimensions}</p>
          <p className="mt-4"><strong>Description:</strong></p>
          <p>{product.description}</p>
        </div>
      </div>
    </Modal>
  );
};

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterCategory, setFilterCategory] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  const fetchProducts = async () => {
    setLoading(true);
    setError(null);
    const productsRef = ref(db, 'products');
    const productsQuery = query(productsRef, orderByChild('name'));

    try {
      const snapshot = await get(productsQuery);
      if (snapshot.exists()) {
        const productList = [];
        snapshot.forEach((childSnapshot) => {
          productList.push({ id: childSnapshot.key, ...childSnapshot.val() });
        });
        setProducts(productList);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      setError("Failed to fetch products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const filteredAndSortedProducts = useMemo(() => {
    return products
      .filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (filterCategory === '' || product.category === filterCategory)
      )
      .sort((a, b) => {
        if (sortOrder === 'asc') {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
  }, [products, searchTerm, filterCategory, sortOrder]);

  const paginatedProducts = useMemo(() => {
    const startIndex = (currentPage - 1) * productsPerPage;
    return filteredAndSortedProducts.slice(startIndex, startIndex + productsPerPage);
  }, [filteredAndSortedProducts, currentPage]);

  const totalPages = Math.ceil(filteredAndSortedProducts.length / productsPerPage);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (e) => {
    setSortOrder(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryFilter = (e) => {
    setFilterCategory(e.target.value);
    setCurrentPage(1);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedProducts(paginatedProducts.map(product => product.id));
    } else {
      setSelectedProducts([]);
    }
  };

  const handleSelectProduct = (productId) => {
    setSelectedProducts(prevSelected => 
      prevSelected.includes(productId)
        ? prevSelected.filter(id => id !== productId)
        : [...prevSelected, productId]
    );
  };

  const handleDeleteSelected = async () => {
    if (window.confirm(`Are you sure you want to delete ${selectedProducts.length} selected products? This action cannot be undone.`)) {
      setIsDeleting(true);
      try {
        for (let productId of selectedProducts) {
          await remove(ref(db, `products/${productId}`));
        }
        await fetchProducts();
        setSelectedProducts([]);
      } catch (error) {
        console.error("Error deleting products:", error);
        setError("Failed to delete selected products. Please try again.");
      }
      setIsDeleting(false);
    }
  };

  const handleViewDetails = (product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Products</h1>
        <Link to="/add-product" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
          Add Product
        </Link>
      </div>
      
      <div className="mb-6 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
          className="border rounded px-4 py-2 w-full sm:w-64"
        />
        <select
          value={sortOrder}
          onChange={handleSort}
          className="border rounded px-4 py-2 w-full sm:w-auto"
        >
          <option value="asc">Sort A-Z</option>
          <option value="desc">Sort Z-A</option>
        </select>
        <select
          value={filterCategory}
          onChange={handleCategoryFilter}
          className="border rounded px-4 py-2 w-full sm:w-auto"
        >
          <option value="">All Categories</option>
          <option value="Whiskey">Whiskey</option>
          <option value="Vodka">Vodka</option>
          <option value="Gin">Gin</option>
          <option value="Rum">Rum</option>
          <option value="Tequila">Tequila</option>
          <option value="Wine">Wine</option>
          <option value="Beer">Beer</option>
          <option value="Liqueurs">Liqueurs</option>
          <option value="Brandy">Brandy</option>
          <option value="Champagne">Champagne</option>
        </select>
      </div>

      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          id="selectAll"
          checked={selectedProducts.length === paginatedProducts.length && paginatedProducts.length > 0}
          onChange={handleSelectAll}
          className="form-checkbox h-5 w-5 text-blue-600"
        />
        <label htmlFor="selectAll" className="ml-2">Select All</label>
        {selectedProducts.length > 0 && (
          <button
            onClick={handleDeleteSelected}
            className="ml-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:bg-red-300"
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : `Delete Selected (${selectedProducts.length})`}
          </button>
        )}
      </div>

      {error ? (
        <div className="text-red-500 text-center my-8">
          <p>{error}</p>
          <button 
            onClick={fetchProducts} 
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      ) : loading ? (
        <p className="text-center mt-4">Loading...</p>
      ) : paginatedProducts.length === 0 ? (
        <p className="text-center text-gray-500 my-8">No products found.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
          {paginatedProducts.map(product => (
            <ProductCard
              key={product.id}
              product={product}
              isSelected={selectedProducts.includes(product.id)}
              onSelect={handleSelectProduct}
              onViewDetails={handleViewDetails}
            />
          ))}
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex justify-center mt-8">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || loading}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                disabled={loading}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                  currentPage === index + 1 ? 'text-blue-600 bg-blue-50' : 'text-gray-700 hover:bg-gray-50'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages || loading}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              Next
            </button>
          </nav>
        </div>
      )}

      <ProductDetailsModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        product={selectedProduct}
      />
    </div>
  );
};

export default Products;