import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ref, onValue, remove } from 'firebase/database';
import { db } from '../firebase';

const Supervisor = () => {
  const [supervisors, setSupervisors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const supervisorsRef = ref(db, 'supervisors');
    const unsubscribe = onValue(supervisorsRef, (snapshot) => {
      setLoading(true);
      if (snapshot.exists()) {
        const supervisorsData = snapshot.val();
        const supervisorsList = Object.keys(supervisorsData).map(key => ({
          id: key,
          ...supervisorsData[key]
        }));
        setSupervisors(supervisorsList);
      } else {
        setSupervisors([]);
      }
      setLoading(false);
    }, (error) => {
      console.error("Error fetching supervisors:", error);
      setError("Failed to fetch supervisors. Please try again later.");
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this supervisor?")) {
      try {
        await remove(ref(db, `supervisors/${id}`));
      } catch (error) {
        console.error("Error deleting supervisor:", error);
        setError("Failed to delete supervisor. Please try again.");
      }
    }
  };

  if (loading) return <p>Loading supervisors...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Supervisors</h1>
      <Link to="/add-supervisor" className="bg-blue-500 text-white px-4 py-2 rounded mb-4 inline-block">
        Add New Supervisor
      </Link>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {supervisors.map(supervisor => (
          <div key={supervisor.id} className="border p-4 rounded shadow">
            <h2 className="text-xl font-semibold">{supervisor.name}</h2>
            <p>Email: {supervisor.email}</p>
            <p>Phone: {supervisor.phone}</p>
            <div className="mt-4 flex justify-between">
              <Link to={`/supervisor/${supervisor.id}`} className="text-blue-500">
                View Details
              </Link>
              <Link to={`/edit-supervisor/${supervisor.id}`} className="text-yellow-500">
                Edit
              </Link>
              <button onClick={() => handleDelete(supervisor.id)} className="text-red-500">
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Supervisor;