import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ref, query, orderByChild, get, remove } from 'firebase/database';
import { db } from '../firebase';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-2xl">&times;</button>
        </div>
        {children}
      </div>
    </div>
  );
};

const EquipmentCard = ({ equipment, isSelected, onSelect, onViewDetails }) => (
  <div className={`bg-white rounded-lg shadow-md p-4 flex flex-col justify-between ${isSelected ? 'border-2 border-blue-500' : ''}`}>
    <div className="flex justify-between items-start">
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => onSelect(equipment.id)}
        className="form-checkbox h-5 w-5 text-blue-600"
      />
    </div>
    <div className="flex-grow flex flex-col">
      <div className="relative pb-[100%] mb-4">
        <img 
          src={equipment.imageURL} 
          alt={equipment.name} 
          className="absolute inset-0 w-full h-full object-contain rounded cursor-pointer"
          onClick={() => onViewDetails(equipment)}
        />
      </div>
      <h3 className="text-lg font-semibold mb-2">{equipment.name}</h3>
      <p className="text-sm text-gray-500 mb-2">Category: {equipment.category}</p>
      <p className="text-sm text-gray-500 mb-2">Status: {equipment.status}</p>
      <p className="text-sm text-gray-500 mb-4">Quantity: {equipment.quantity}</p>
    </div>
    <div className="flex justify-between">
      <button onClick={() => onViewDetails(equipment)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 text-sm">
        View Details
      </button>
      <Link to={`/edit-equipment/${equipment.id}`} className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 text-sm">
        Edit
      </Link>
    </div>
  </div>
);

const EquipmentDetailsModal = ({ isOpen, onClose, equipment }) => {
  if (!equipment) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-2xl font-bold mb-4">{equipment.name}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="relative pb-[100%]">
          <img 
            src={equipment.imageURL} 
            alt={equipment.name} 
            className="absolute inset-0 w-full h-full object-contain rounded"
          />
        </div>
        <div>
          <p className="mb-2"><strong>Category:</strong> {equipment.category}</p>
          <p className="mb-2"><strong>Status:</strong> {equipment.status}</p>
          <p className="mb-2"><strong>Quantity:</strong> {equipment.quantity}</p>
          <p className="mb-2"><strong>Brand:</strong> {equipment.brand}</p>
          <p className="mb-2"><strong>Model:</strong> {equipment.model}</p>
          <p className="mb-2"><strong>Serial Number:</strong> {equipment.serialNumber}</p>
          <p className="mb-2"><strong>Purchase Date:</strong> {equipment.purchaseDate}</p>
          <p className="mb-2"><strong>Last Maintenance:</strong> {equipment.lastMaintenance}</p>
          <p className="mb-2"><strong>Next Maintenance Due:</strong> {equipment.nextMaintenanceDue}</p>
          <p className="mt-4"><strong>Description:</strong></p>
          <p>{equipment.description}</p>
        </div>
      </div>
    </Modal>
  );
};

const Equipments = () => {
  const [equipments, setEquipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterCategory, setFilterCategory] = useState('');
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const equipmentsPerPage = 10;

  const fetchEquipments = async () => {
    setLoading(true);
    setError(null);
    const equipmentsRef = ref(db, 'equipments');
    const equipmentsQuery = query(equipmentsRef, orderByChild('name'));

    try {
      const snapshot = await get(equipmentsQuery);
      if (snapshot.exists()) {
        const equipmentList = [];
        snapshot.forEach((childSnapshot) => {
          equipmentList.push({ id: childSnapshot.key, ...childSnapshot.val() });
        });
        setEquipments(equipmentList);
      } else {
        setEquipments([]);
      }
    } catch (error) {
      console.error("Error fetching equipments:", error);
      setError("Failed to fetch equipments. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEquipments();
  }, []);

  const filteredAndSortedEquipments = useMemo(() => {
    return equipments
      .filter(equipment =>
        equipment.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (filterCategory === '' || equipment.category === filterCategory)
      )
      .sort((a, b) => {
        if (sortOrder === 'asc') {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
  }, [equipments, searchTerm, filterCategory, sortOrder]);

  const paginatedEquipments = useMemo(() => {
    const startIndex = (currentPage - 1) * equipmentsPerPage;
    return filteredAndSortedEquipments.slice(startIndex, startIndex + equipmentsPerPage);
  }, [filteredAndSortedEquipments, currentPage]);

  const totalPages = Math.ceil(filteredAndSortedEquipments.length / equipmentsPerPage);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (e) => {
    setSortOrder(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryFilter = (e) => {
    setFilterCategory(e.target.value);
    setCurrentPage(1);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedEquipments(paginatedEquipments.map(equipment => equipment.id));
    } else {
      setSelectedEquipments([]);
    }
  };

  const handleSelectEquipment = (equipmentId) => {
    setSelectedEquipments(prevSelected => 
      prevSelected.includes(equipmentId)
        ? prevSelected.filter(id => id !== equipmentId)
        : [...prevSelected, equipmentId]
    );
  };

  const handleDeleteSelected = async () => {
    if (window.confirm(`Are you sure you want to delete ${selectedEquipments.length} selected equipments? This action cannot be undone.`)) {
      setIsDeleting(true);
      try {
        for (let equipmentId of selectedEquipments) {
          await remove(ref(db, `equipments/${equipmentId}`));
        }
        await fetchEquipments();
        setSelectedEquipments([]);
      } catch (error) {
        console.error("Error deleting equipments:", error);
        setError("Failed to delete selected equipments. Please try again.");
      }
      setIsDeleting(false);
    }
  };

  const handleViewDetails = (equipment) => {
    setSelectedEquipment(equipment);
    setModalOpen(true);
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Event Equipment</h1>
        <Link to="/add-equipment" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
          Add Equipment
        </Link>
      </div>
      
      <div className="mb-6 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
        <input
          type="text"
          placeholder="Search equipment..."
          value={searchTerm}
          onChange={handleSearch}
          className="border rounded px-4 py-2 w-full sm:w-64"
        />
        <select
          value={sortOrder}
          onChange={handleSort}
          className="border rounded px-4 py-2 w-full sm:w-auto"
        >
          <option value="asc">Sort A-Z</option>
          <option value="desc">Sort Z-A</option>
        </select>
        <select
          value={filterCategory}
          onChange={handleCategoryFilter}
          className="border rounded px-4 py-2 w-full sm:w-auto"
        >
          <option value="">All Categories</option>
          <option value="Audio">Audio</option>
          <option value="Visual">Visual</option>
          <option value="Lighting">Lighting</option>
          <option value="Staging">Staging</option>
          <option value="Furniture">Furniture</option>
          <option value="Decor">Decor</option>
          <option value="Power">Power & Electrical</option>
          <option value="Rigging">Rigging & Truss</option>
          <option value="Event Bars">Event Bars</option>
        </select>
      </div>

      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          id="selectAll"
          checked={selectedEquipments.length === paginatedEquipments.length && paginatedEquipments.length > 0}
          onChange={handleSelectAll}
          className="form-checkbox h-5 w-5 text-blue-600"
        />
        <label htmlFor="selectAll" className="ml-2">Select All</label>
        {selectedEquipments.length > 0 && (
          <button
            onClick={handleDeleteSelected}
            className="ml-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:bg-red-300"
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : `Delete Selected (${selectedEquipments.length})`}
          </button>
        )}
      </div>

      {error ? (
        <div className="text-red-500 text-center my-8">
          <p>{error}</p>
          <button 
            onClick={fetchEquipments} 
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      ) : loading ? (
        <p className="text-center mt-4">Loading...</p>
      ) : paginatedEquipments.length === 0 ? (
        <p className="text-center text-gray-500 my-8">No equipment found.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
          {paginatedEquipments.map(equipment => (
            <EquipmentCard
              key={equipment.id}
              equipment={equipment}
              isSelected={selectedEquipments.includes(equipment.id)}
              onSelect={handleSelectEquipment}
              onViewDetails={handleViewDetails}
            />
          ))}
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex justify-center mt-8">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || loading}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                disabled={loading}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                  currentPage === index + 1 ? 'text-blue-600 bg-blue-50' : 'text-gray-700 hover:bg-gray-50'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages || loading}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              Next
            </button>
          </nav>
        </div>
      )}

      <EquipmentDetailsModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        equipment={selectedEquipment}
      />
    </div>
  );
};

export default Equipments;