import React, { useState, useEffect } from 'react';
import { ref, push, query, orderByChild, get } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase';
import axios from 'axios';

const SendNotification = () => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [topic, setTopic] = useState('all_users');
  const [status, setStatus] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [idToken, setIdToken] = useState('');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setIdToken(token);
      } else {
        setIdToken('');
      }
    });

    fetchNotifications();

    return () => unsubscribe();
  }, []);

  const fetchNotifications = async () => {
    setLoading(true);
    setError(null);
    const notificationsRef = ref(db, 'notifications');
    const notificationsQuery = query(notificationsRef, orderByChild('timestamp'));

    try {
      const snapshot = await get(notificationsQuery);
      if (snapshot.exists()) {
        const notificationList = Object.entries(snapshot.val()).map(([id, data]) => ({
          id,
          ...data,
        }));
        setNotifications(notificationList.reverse());
      } else {
        setNotifications([]);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setError('Failed to fetch notifications. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const sendNotification = async (e) => {
    e.preventDefault();
    setStatus('Sending...');
    setError(null);

    try {
      const response = await axios.post(
        'https://us-central1-event-management-app-3507f.cloudfunctions.net/sendNotification',
        { title, body, topic },
        { headers: { Authorization: idToken } }
      );

      console.log('Notification sent successfully:', response.data);
      setStatus('Notification sent successfully');

      // Save notification to database
      const notificationsRef = ref(db, 'notifications');
      await push(notificationsRef, {
        title,
        body,
        topic,
        timestamp: Date.now(),
      });

      // Refresh notifications list
      fetchNotifications();

      // Clear form
      setTitle('');
      setBody('');
      setTopic('all_users');
    } catch (error) {
      console.error('Error sending notification:', error);
      setStatus(`Error: Unable to send notification. ${error.message}`);
      setError('An error occurred while trying to send the notification. Please check the console for more details.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Send Notification</h1>
      
      <form onSubmit={sendNotification} className="mb-8">
        <div className="mb-4">
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="body" className="block text-sm font-medium text-gray-700">Body:</label>
          <textarea
            id="body"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="topic" className="block text-sm font-medium text-gray-700">Topic:</label>
          <input
            type="text"
            id="topic"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Send Notification</button>
      </form>
      
      {status && (
        <p className={`mb-4 text-sm font-medium ${error ? 'text-red-600' : 'text-green-600'}`}>
          {status}
        </p>
      )}

      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}

      <h2 className="text-2xl font-bold mb-4">Sent Notifications</h2>
      
      {error && error.includes('fetch notifications') ? (
        <div className="text-red-500 text-center my-8">
          <p>{error}</p>
          <button 
            onClick={fetchNotifications} 
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      ) : loading ? (
        <p className="text-center mt-4">Loading...</p>
      ) : notifications.length === 0 ? (
        <p className="text-center text-gray-500 my-8">No notifications sent yet.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Title
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Body
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Topic
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Sent At
                </th>
              </tr>
            </thead>
            <tbody>
              {notifications.map((notification) => (
                <tr key={notification.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{notification.title}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{notification.body.length > 50 ? `${notification.body.substring(0, 50)}...` : notification.body}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{notification.topic}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{new Date(notification.timestamp).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SendNotification;