import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';

const EventInventoryAI = () => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('category');
  const [sortDirection, setSortDirection] = useState('asc');
  const navigate = useNavigate();

  useEffect(() => {
    fetchInventoryItems();
  }, []);

  const fetchInventoryItems = async () => {
    try {
      const response = await fetch('/getInventoryItems');
      const data = await response.json();
      if (data.success) {
        setInventoryItems(data.items);
      } else {
        throw new Error(data.error);
      }
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch inventory items: ' + err.message);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this inventory item?')) {
      try {
        await deleteDoc(doc(db, 'eventInventory', id));
        fetchInventoryItems();
      } catch (err) {
        setError('Failed to delete inventory item: ' + err.message);
      }
    }
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedItems = useMemo(() => {
    return inventoryItems
      .filter(item => 
        item.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.serialNumber.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [inventoryItems, searchTerm, sortField, sortDirection]);

  if (loading) return <p>Loading inventory items...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-3xl font-bold">Event Inventory</h1>
        <button
          onClick={() => navigate('/add-inventory-item')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Inventory Item
        </button>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Search inventory..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border rounded px-2 py-1 w-64"
        />
      </div>

      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="border p-2 cursor-pointer" onClick={() => handleSort('category')}>
                Category {sortField === 'category' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className="border p-2 cursor-pointer" onClick={() => handleSort('status')}>
                Status {sortField === 'status' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className="border p-2 cursor-pointer" onClick={() => handleSort('quantity')}>
                Quantity {sortField === 'quantity' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className="border p-2 cursor-pointer" onClick={() => handleSort('brand')}>
                Brand {sortField === 'brand' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className="border p-2 cursor-pointer" onClick={() => handleSort('model')}>
                Model {sortField === 'model' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className="border p-2">Serial Number</th>
              <th className="border p-2 cursor-pointer" onClick={() => handleSort('purchaseDate')}>
                Purchase Date {sortField === 'purchaseDate' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className="border p-2">Last Maintenance</th>
              <th className="border p-2">Next Maintenance Due</th>
              <th className="border p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredAndSortedItems.map((item) => (
              <tr key={item.id}>
                <td className="border p-2">{item.category}</td>
                <td className="border p-2">{item.status}</td>
                <td className="border p-2">{item.quantity}</td>
                <td className="border p-2">{item.brand}</td>
                <td className="border p-2">{item.model}</td>
                <td className="border p-2">{item.serialNumber}</td>
                <td className="border p-2">{new Date(item.purchaseDate).toLocaleDateString()}</td>
                <td className="border p-2">{new Date(item.lastMaintenance).toLocaleDateString()}</td>
                <td className="border p-2">{new Date(item.nextMaintenanceDue).toLocaleDateString()}</td>
                <td className="border p-2">
                  <Link to={`/inventory/${item.id}`} className="text-blue-600 hover:text-blue-900 mr-2">View</Link>
                  <Link to={`/edit-inventory/${item.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">Edit</Link>
                  <button onClick={() => handleDelete(item.id)} className="text-red-600 hover:text-red-900">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {filteredAndSortedItems.length === 0 && (
        <p className="text-center mt-4">No inventory items found.</p>
      )}
    </div>
  );
};

export default EventInventoryAI;