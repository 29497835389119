import React, { useState, useCallback, useRef } from 'react';
import { PieChart, Pie, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D', '#A4DE6C', '#D0ED57', '#FFA07A', '#20B2AA'];

// Sample data
const sampleData = {
  topEquipments: [
    { name: 'Speakers', value: 150 },
    { name: 'Microphones', value: 120 },
    { name: 'Projectors', value: 100 },
    { name: 'Lights', value: 80 },
    { name: 'Chairs', value: 75 },
    { name: 'Tables', value: 70 },
    { name: 'Stage', value: 50 },
    { name: 'Cameras', value: 40 },
    { name: 'Mixers', value: 35 },
    { name: 'Screens', value: 30 },
  ],
  topProducts: [
    { name: 'Whiskey', value: 200 },
    { name: 'Vodka', value: 180 },
    { name: 'Beer', value: 160 },
    { name: 'Wine', value: 140 },
    { name: 'Gin', value: 120 },
    { name: 'Rum', value: 100 },
    { name: 'Tequila', value: 80 },
    { name: 'Champagne', value: 60 },
    { name: 'Brandy', value: 40 },
    { name: 'Liqueur', value: 20 },
  ],
  salesByEvent: [
    { name: 'Jan', Wedding: 4000, Corporate: 2400, Birthday: 2400 },
    { name: 'Feb', Wedding: 3000, Corporate: 1398, Birthday: 2210 },
    { name: 'Mar', Wedding: 2000, Corporate: 9800, Birthday: 2290 },
    { name: 'Apr', Wedding: 2780, Corporate: 3908, Birthday: 2000 },
    { name: 'May', Wedding: 1890, Corporate: 4800, Birthday: 2181 },
    { name: 'Jun', Wedding: 2390, Corporate: 3800, Birthday: 2500 },
    { name: 'Jul', Wedding: 3490, Corporate: 4300, Birthday: 2100 },
    { name: 'Aug', Wedding: 4000, Corporate: 2400, Birthday: 2400 },
    { name: 'Sep', Wedding: 3000, Corporate: 1398, Birthday: 2210 },
    { name: 'Oct', Wedding: 2000, Corporate: 9800, Birthday: 2290 },
    { name: 'Nov', Wedding: 2780, Corporate: 3908, Birthday: 2000 },
    { name: 'Dec', Wedding: 3490, Corporate: 4300, Birthday: 2100 },
  ],
  eventsByCategory: [
    { name: 'Wedding', value: 35 },
    { name: 'Corporate', value: 25 },
    { name: 'Birthday', value: 20 },
    { name: 'Anniversary', value: 10 },
    { name: 'Graduation', value: 5 },
    { name: 'Other', value: 5 },
  ],
  topClients: [
    { name: 'J. Wray and Nephew', value: 50 },
    { name: 'Red Stripe', value: 45 },
    { name: 'Romeich Entertainment', value: 40 },
    { name: 'Seprod', value: 35 },
    { name: 'Best Dressed Chicken', value: 30 },
    { name: 'Best Events Co.', value: 25 },
    { name: 'Party Planners Inc.', value: 20 },
    { name: 'Corporate Solutions', value: 15 },
    { name: 'Wedding Dreams', value: 10 },
    { name: 'Celebration Experts', value: 5 },
  ],
};

const Reports = () => {
  const [reportType, setReportType] = useState('topEquipments');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const reportRef = useRef(null);

  const getChartData = () => {
    switch (reportType) {
      case 'topEquipments':
        return sampleData.topEquipments;
      case 'topProducts':
        return sampleData.topProducts;
      case 'salesByEvent':
        return sampleData.salesByEvent;
      case 'eventsByCategory':
        return sampleData.eventsByCategory;
      case 'topClients':
        return sampleData.topClients;
      default:
        return [];
    }
  };

  const renderChart = () => {
    const data = getChartData();

    switch (reportType) {
      case 'topEquipments':
      case 'topProducts':
      case 'eventsByCategory':
      case 'topClients':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#8884d8"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        );
      case 'salesByEvent':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Wedding" fill="#8884d8" />
              <Bar dataKey="Corporate" fill="#82ca9d" />
              <Bar dataKey="Birthday" fill="#ffc658" />
            </BarChart>
          </ResponsiveContainer>
        );
      default:
        return null;
    }
  };

  const handlePrint = useCallback(async () => {
    if (reportRef.current) {
      setIsGeneratingPDF(true);
      const input = reportRef.current;
      const canvas = await html2canvas(input, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth - 20;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 10;

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight - 20;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pdfHeight - 20;
      }

      pdf.save(`${reportType}_report.pdf`);
      setIsGeneratingPDF(false);
    }
  }, [reportType]);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Reports</h1>
      <div className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label htmlFor="reportType" className="block mb-2">Select Report Type:</label>
          <select
            id="reportType"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="topEquipments">Top 10 Equipments</option>
            <option value="topProducts">Top 10 Products</option>
            <option value="salesByEvent">Total Sales by Event Over Time</option>
            <option value="eventsByCategory">Events by Category</option>
            <option value="topClients">Top 10 Clients</option>
          </select>
        </div>
        <div>
          <label htmlFor="startDate" className="block mb-2">Start Date:</label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="endDate" className="block mb-2">End Date:</label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
      </div>
      <button
        onClick={handlePrint}
        disabled={isGeneratingPDF}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4 disabled:bg-blue-300"
      >
        {isGeneratingPDF ? 'Generating PDF...' : 'Generate PDF Report'}
      </button>
      <div id="report-container" ref={reportRef} className="bg-white p-4 rounded shadow">
        <h2 className="text-2xl font-semibold mb-4">
          {reportType === 'topEquipments' && 'Top 10 Equipments'}
          {reportType === 'topProducts' && 'Top 10 Products'}
          {reportType === 'salesByEvent' && 'Total Sales by Event Over Time'}
          {reportType === 'eventsByCategory' && 'Events by Category'}
          {reportType === 'topClients' && 'Top 10 Clients'}
        </h2>
        <div style={{ width: '100%', height: '400px' }}>
          {renderChart()}
        </div>
      </div>
    </div>
  );
};

export default Reports;