import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, push, get } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';

const statuses = [
  { id: 0, name: 'Planning', description: 'Initial planning and preparation' },
  { id: 1, name: 'Booking', description: 'Booking venues and services' },
  { id: 2, name: 'Invitations', description: 'Sending out invitations' },
  { id: 3, name: 'Preparations', description: 'Final preparations' },
  { id: 4, name: 'In Progress', description: 'Event is currently happening' },
  { id: 5, name: 'Completed', description: 'Event has concluded' }
];

const AddEvent = () => {
  const [event, setEvent] = useState({
    name: '',
    clientId: '',
    date: '',
    status: 0,
    location: '',
    expectedAttendees: '',
    description: '',
    products: [],
    equipment: [],
    eventSupervisorId: '',
    staffCount: 0,
    images: []
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [clients, setClients] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [productSearch, setProductSearch] = useState('');
  const [equipmentSearch, setEquipmentSearch] = useState('');
  const [productResults, setProductResults] = useState([]);
  const [equipmentResults, setEquipmentResults] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      const clientsRef = ref(db, 'clients');
      const snapshot = await get(clientsRef);
      if (snapshot.exists()) {
        const clientsData = snapshot.val();
        const clientsList = Object.keys(clientsData).map(key => ({
          id: key,
          ...clientsData[key]
        }));
        setClients(clientsList);
      }
    };

    const fetchSupervisors = async () => {
      const supervisorsRef = ref(db, 'supervisors');
      const snapshot = await get(supervisorsRef);
      if (snapshot.exists()) {
        const supervisorsData = snapshot.val();
        const supervisorsList = Object.keys(supervisorsData).map(key => ({
          id: key,
          ...supervisorsData[key]
        }));
        setSupervisors(supervisorsList);
      }
    };

    fetchClients();
    fetchSupervisors();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent(prevEvent => ({
      ...prevEvent,
      [name]: name === 'status' || name === 'staffCount' || name === 'expectedAttendees' 
        ? parseInt(value, 10) 
        : value
    }));
  };

  const handleProductSearch = async (e) => {
    setProductSearch(e.target.value);
    if (e.target.value.length > 2) {
      const productsRef = ref(db, 'products');
      const snapshot = await get(productsRef);
      if (snapshot.exists()) {
        const allProducts = Object.entries(snapshot.val()).map(([id, data]) => ({ id, ...data }));
        const filtered = allProducts.filter(product => 
          product.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setProductResults(filtered);
      }
    } else {
      setProductResults([]);
    }
  };

  const handleEquipmentSearch = async (e) => {
    setEquipmentSearch(e.target.value);
    if (e.target.value.length > 2) {
      const equipmentRef = ref(db, 'equipments');
      const snapshot = await get(equipmentRef);
      if (snapshot.exists()) {
        const allEquipment = Object.entries(snapshot.val()).map(([id, data]) => ({ id, ...data }));
        const filtered = allEquipment.filter(equipment => 
          equipment.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setEquipmentResults(filtered);
      }
    } else {
      setEquipmentResults([]);
    }
  };

  const addProduct = (product) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      products: [...prevEvent.products, product]
    }));
    setProductSearch('');
    setProductResults([]);
  };

  const addEquipment = (equipment) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      equipment: [...prevEvent.equipment, equipment]
    }));
    setEquipmentSearch('');
    setEquipmentResults([]);
  };

  const removeProduct = (productId) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      products: prevEvent.products.filter(p => p.id !== productId)
    }));
  };

  const removeEquipment = (equipmentId) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      equipment: prevEvent.equipment.filter(e => e.id !== equipmentId)
    }));
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const newImages = [];
  
    for (let file of files) {
      const imageRef = storageRef(storage, `events/${Date.now()}/${file.name}`);
      try {
        const snapshot = await uploadBytes(imageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        newImages.push(downloadURL);
      } catch (error) {
        console.error("Error uploading image:", error);
        setError("Failed to upload image. Please try again.");
      }
    }
  
    setEvent(prevEvent => ({
      ...prevEvent,
      images: [...prevEvent.images, ...newImages]
    }));
  };

  const removeImage = (index) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      images: prevEvent.images.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const eventsRef = ref(db, 'events');
      await push(eventsRef, {
        ...event,
        products: event.products.map(p => p.id),
        equipment: event.equipment.map(e => e.id),
      });
      setLoading(false);
      navigate('/events');
    } catch (error) {
      console.error("Error adding event: ", error);
      setError("Failed to add event. Please check your database permissions and try again.");
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Add New Event</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="name" className="block mb-2">Event Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={event.name}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label htmlFor="clientId" className="block mb-2">Client</label>
            <select
              id="clientId"
              name="clientId"
              value={event.clientId}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            >
              <option value="">Select a client</option>
              {clients.map(client => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="date" className="block mb-2">Event Date</label>
            <input
              type="date"
              id="date"
              name="date"
              value={event.date}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label htmlFor="status" className="block mb-2">Status</label>
            <select
              id="status"
              name="status"
              value={event.status}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            >
              {statuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="location" className="block mb-2">Location</label>
            <input
              type="text"
              id="location"
              name="location"
              value={event.location}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label htmlFor="expectedAttendees" className="block mb-2">Expected Attendees</label>
            <input
              type="number"
              id="expectedAttendees"
              name="expectedAttendees"
              value={event.expectedAttendees}
              onChange={handleChange}
              required
              min="0"
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label htmlFor="eventSupervisorId" className="block mb-2">Event Supervisor</label>
            <select
              id="eventSupervisorId"
              name="eventSupervisorId"
              value={event.eventSupervisorId}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            >
              <option value="">Select a supervisor</option>
              {supervisors.map((supervisor) => (
                <option key={supervisor.id} value={supervisor.id}>
                  {supervisor.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="staffCount" className="block mb-2">Staff Count</label>
            <input
              type="number"
              id="staffCount"
              name="staffCount"
              value={event.staffCount}
              onChange={handleChange}
              required
              min="0"
              className="w-full px-3 py-2 border rounded"
            />
          </div>
        </div>
        
        <div>
          <label htmlFor="description" className="block mb-2">Description</label>
          <textarea
            id="description"
            name="description"
            value={event.description}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
            rows="4"
          />
        </div>

        <div>
          <label htmlFor="productSearch" className="block mb-2">Add Products</label>
          <input
            type="text"
            id="productSearch"
            value={productSearch}
            onChange={handleProductSearch}
            placeholder="Search for products..."
            className="w-full px-3 py-2 border rounded mb-2"
          />
          {productResults.length > 0 && (
            <ul className="border rounded mt-2 max-h-40 overflow-y-auto">
              {productResults.map(product => (
                <li 
                  key={product.id} 
                  className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => addProduct(product)}
                >
                  {product.name}
                </li>
              ))}
            </ul>
          )}
          {event.products.length > 0 && (
            <div className="mt-2">
              <h4 className="font-semibold">Selected Products:</h4>
              <ul>
                {event.products.map(product => (
                  <li key={product.id} className="flex justify-between items-center py-1">
                    <span>{product.name}</span>
                    <button 
                      type="button"
                      onClick={() => removeProduct(product.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div>
          <label htmlFor="equipmentSearch" className="block mb-2">Add Equipment</label>
          <input
            type="text"
            id="equipmentSearch"
            value={equipmentSearch}
            onChange={handleEquipmentSearch}
            placeholder="Search for equipment..."
            className="w-full px-3 py-2 border rounded mb-2"
          />
          {equipmentResults.length > 0 && (
            <ul className="border rounded mt-2 max-h-40 overflow-y-auto">
              {equipmentResults.map(equipment => (
                <li 
                  key={equipment.id} 
                  className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => addEquipment(equipment)}
                >
                  {equipment.name}
                </li>
              ))}
            </ul>
          )}
          {event.equipment.length > 0 && (
            <div className="mt-2">
              <h4 className="font-semibold">Selected Equipment:</h4>
              <ul>
                {event.equipment.map(equipment => (
                  <li key={equipment.id} className="flex justify-between items-center py-1">
                    <span>{equipment.name}</span>
                    <button 
                      type="button"
                      onClick={() => removeEquipment(equipment.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div>
          <label htmlFor="images" className="block mb-2">Event Images</label>
          <input
            type="file"
            id="images"
            multiple
            accept="image/*"
            onChange={handleImageUpload}
            className="w-full px-3 py-2 border rounded"
          />
          {event.images.length > 0 && (
            <div className="mt-4 grid grid-cols-3 gap-4">
              {event.images.map((image, index) => (
                <div key={index} className="relative">
                  <img src={image} alt={`Event ${index + 1}`} className="w-full h-32 object-cover rounded" />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <button 
          type="submit" 
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
          disabled={loading}
        >
          {loading ? 'Adding Event...' : 'Add Event'}
        </button>
      </form>
    </div>
  );
};

export default AddEvent;