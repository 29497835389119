import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Unicore Event Pro - Terms and Conditions</h1>
      
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">1. Acceptance of Terms</h2>
        <p>By downloading, installing, or using the Unicore Event Pro app, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, do not use the app.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">2. Use of the App</h2>
        <ul className="list-disc pl-6">
          <li>You must be at least 13 years old to use this app.</li>
          <li>You are responsible for maintaining the confidentiality of your account and password.</li>
          <li>You agree to use the app only for lawful purposes and in accordance with these Terms.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">3. Intellectual Property</h2>
        <p>The app and its original content, features, and functionality are owned by Unicore and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">4. User Content</h2>
        <ul className="list-disc pl-6">
          <li>You retain any copyright that you may hold in content you submit to the app.</li>
          <li>By submitting content, you grant Unicore a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute that content in connection with the service.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">5. Prohibited Uses</h2>
        <p>You agree not to use the app:</p>
        <ul className="list-disc pl-6">
          <li>In any way that violates any applicable national, federal, state, local or international law or regulation.</li>
          <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">6. Termination</h2>
        <p>We may terminate or suspend your account and bar access to the app immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever, including without limitation if you breach the Terms.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">7. Limitation of Liability</h2>
        <p>In no event shall Unicore, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the app.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">8. Changes to Terms</h2>
        <p>We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">9. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at how.britton@gmail.com.</p>
      </section>

      <p className="mt-8 text-sm text-gray-600">Last updated: {new Date().toLocaleDateString()}</p>
    </div>
  );
};

export default TermsAndConditions;