import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Unicore Event Pro - Privacy Policy</h1>
      
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">1. Introduction</h2>
        <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Unicore Event Pro mobile application.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">2. Information We Collect</h2>
        <ul className="list-disc pl-6">
          <li>Personal Information: When you register an account, we may collect your name, email address, and phone number.</li>
          <li>Event Information: We collect information about the events you create and manage, including event details, guest lists, and vendor information.</li>
          <li>Usage Data: We collect data about how you interact with our app, including app features used and time spent on various screens.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">3. How We Use Your Information</h2>
        <p>We use the collected information for various purposes:</p>
        <ul className="list-disc pl-6">
          <li>To provide and maintain our service</li>
          <li>To notify you about changes to our service</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information so that we can improve our service</li>
          <li>To monitor the usage of our service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">4. Data Storage and Security</h2>
        <p>We use commercially acceptable means to protect your personal information, but remember that no method of transmission over the internet or method of electronic storage is 100% secure.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">5. Disclosure of Data</h2>
        <p>We may disclose your personal information in the good faith belief that such action is necessary to:</p>
        <ul className="list-disc pl-6">
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of Unicore</li>
          <li>Prevent or investigate possible wrongdoing in connection with the service</li>
          <li>Protect the personal safety of users of the service or the public</li>
          <li>Protect against legal liability</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">6. Third-Party Services</h2>
        <p>Our app may contain links to other sites that are not operated by us. We strongly advise you to review the Privacy Policy of every site you visit.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">7. Children's Privacy</h2>
        <p>Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">8. Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">9. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at how.britton@gmail.com.</p>
      </section>

      <p className="mt-8 text-sm text-gray-600">Last updated: {new Date().toLocaleDateString()}</p>
    </div>
  );
};

export default PrivacyPolicy;