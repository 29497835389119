import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import './App.css';
import './index.css';

// Import components
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Sidebar from './components/Sidebar';
import Products from './components/Products';
import AddProduct from './components/AddProduct';
import EditProduct from './components/EditProduct';
import Equipments from './components/Equipments';
import AddEquipment from './components/AddEquipment';
import EditEquipment from './components/EditEquipment';
import Events from './components/Events';
import AddEvent from './components/AddEvent';
import EditEvent from './components/EditEvent';
import Supervisor from './components/Supervisor';
import AddSupervisor from './components/AddSupervisor';
import EditSupervisor from './components/EditSupervisor';
import SupervisorDetails from './components/SupervisorDetails';
import Clients from './components/Clients';
import AddClient from './components/AddClient';
import ViewClient from './components/ViewClient';
import EditClient from './components/EditClient';
import Reports from './components/reports';
import SendNotification from './components/SendNotification';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import EventInventoryAI from './components/EventInventoryAI';
// import Users from './components/Users';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const ProtectedRoute = ({ children }) => {
    if (loading) return <div>Loading...</div>;
    return user ? (
      <div className="flex">
        <Sidebar />
        <div className="flex-1">
          {children}
        </div>
      </div>
    ) : <Navigate to="/login" />;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Public routes */}
          <Route path="/register" element={user ? <Navigate to="/dashboard" /> : <Register />} />
          <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />

          {/* Protected routes */}
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />

          {/* Event AI routes */}
          <Route path="/ai" element={<ProtectedRoute><EventInventoryAI /></ProtectedRoute>} />

          {/* Event routes */}
          <Route path="/events" element={<ProtectedRoute><Events /></ProtectedRoute>} />
          <Route path="/add-event" element={<ProtectedRoute><AddEvent /></ProtectedRoute>} />
          <Route path="/edit-event/:id" element={<ProtectedRoute><EditEvent /></ProtectedRoute>} />
          
          {/* Product routes */}
          <Route path="/products" element={<ProtectedRoute><Products /></ProtectedRoute>} />
          <Route path="/add-product" element={<ProtectedRoute><AddProduct /></ProtectedRoute>} />
          <Route path="/edit-product/:id" element={<ProtectedRoute><EditProduct /></ProtectedRoute>} />

          {/* Equipment routes */}
          <Route path="/equipments" element={<ProtectedRoute><Equipments /></ProtectedRoute>} />
          <Route path="/add-equipment" element={<ProtectedRoute><AddEquipment /></ProtectedRoute>} />
          <Route path="/edit-equipment/:id" element={<ProtectedRoute><EditEquipment /></ProtectedRoute>} />

          {/* Supervisor routes */}
          <Route path="/supervisors" element={<ProtectedRoute><Supervisor /></ProtectedRoute>} />
          <Route path="/add-supervisor" element={<ProtectedRoute><AddSupervisor /></ProtectedRoute>} />
          <Route path="/edit-supervisor/:id" element={<ProtectedRoute><EditSupervisor /></ProtectedRoute>} />
          <Route path="/supervisor/:id" element={<ProtectedRoute><SupervisorDetails /></ProtectedRoute>} />

          {/* Client routes */}
          <Route path="/clients" element={<ProtectedRoute><Clients /></ProtectedRoute>} />
          <Route path="/add-client" element={<ProtectedRoute><AddClient /></ProtectedRoute>} />
          <Route path="/view-client/:id" element={<ProtectedRoute><ViewClient /></ProtectedRoute>} />
          <Route path="/edit-client/:id" element={<ProtectedRoute><EditClient /></ProtectedRoute>} />

          {/* Notification routes */}
          <Route path="/notification" element={<ProtectedRoute><SendNotification /></ProtectedRoute>} />

          {/* Report routes */}
          <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />

          <Route path="/terms" element={<ProtectedRoute><TermsAndConditions /></ProtectedRoute>} />
          <Route path="/privacy" element={<ProtectedRoute><PrivacyPolicy /></ProtectedRoute>} />

          {/* User routes */}
          {/* <Route path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} /> */}

          {/* Default route */}
          <Route path="/" element={<Navigate to={user ? "/dashboard" : "/login"} />} />
          
          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;