import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ref, onValue, remove } from 'firebase/database';
import { db } from '../firebase';

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const clientsRef = ref(db, 'clients');
    const unsubscribe = onValue(clientsRef, (snapshot) => {
      setLoading(true);
      if (snapshot.exists()) {
        const clientsData = snapshot.val();
        const clientsList = Object.keys(clientsData).map(key => ({
          id: key,
          ...clientsData[key]
        }));
        setClients(clientsList);
      } else {
        setClients([]);
      }
      setLoading(false);
    }, (error) => {
      console.error("Error fetching clients:", error);
      setError("Failed to fetch clients. Please try again later.");
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this client?")) {
      try {
        await remove(ref(db, `clients/${id}`));
      } catch (error) {
        console.error("Error deleting client:", error);
        setError("Failed to delete client. Please try again.");
      }
    }
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedClients = useMemo(() => {
    return clients
      .filter(client =>
        client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.phone.includes(searchTerm)
      )
      .sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [clients, searchTerm, sortField, sortDirection]);

  if (loading) return <p>Loading clients...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Clients</h1>
      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Search clients..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border rounded px-2 py-1 w-64"
        />
        <Link to="/add-client" className="bg-blue-500 text-white px-4 py-2 rounded">
          Add New Client
        </Link>
      </div>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th className="border p-2 cursor-pointer" onClick={() => handleSort('name')}>
              Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th className="border p-2 cursor-pointer" onClick={() => handleSort('email')}>
              Email {sortField === 'email' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th className="border p-2 cursor-pointer" onClick={() => handleSort('phone')}>
              Phone {sortField === 'phone' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th className="border p-2 cursor-pointer" onClick={() => handleSort('company')}>
              Company {sortField === 'company' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedClients.map(client => (
            <tr key={client.id}>
              <td className="border p-2">{client.name}</td>
              <td className="border p-2">{client.email}</td>
              <td className="border p-2">{client.phone}</td>
              <td className="border p-2">{client.company || 'N/A'}</td>
              <td className="border p-2">
                <Link to={`/view-client/${client.id}`} className="text-blue-500 mr-2">
                  View
                </Link>
                <Link to={`/edit-client/${client.id}`} className="text-yellow-500 mr-2">
                  Edit
                </Link>
                <button onClick={() => handleDelete(client.id)} className="text-red-500">
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {filteredAndSortedClients.length === 0 && (
        <p className="text-center mt-4">No clients found.</p>
      )}
    </div>
  );
};

export default Clients;