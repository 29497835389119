import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ref, query, orderByChild, get, remove, update } from 'firebase/database';
import { db } from '../firebase';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-2xl">&times;</button>
        </div>
        {children}
      </div>
    </div>
  );
};

const statuses = [
  { id: 0, name: 'Planning', description: 'Initial planning and preparation' },
  { id: 1, name: 'Booking', description: 'Booking venues and services' },
  { id: 2, name: 'Invitations', description: 'Sending out invitations' },
  { id: 3, name: 'Preparations', description: 'Final preparations' },
  { id: 4, name: 'In Progress', description: 'Event is currently happening' },
  { id: 5, name: 'Completed', description: 'Event has concluded' }
];

const EventCard = ({ event, isSelected, onSelect, onViewDetails }) => {
  const status = event.status !== undefined ? event.status : 0;
  const statusObj = statuses[status] || statuses[0];

  return (
    <div className={`bg-white rounded-lg shadow-md p-4 flex flex-col justify-between ${isSelected ? 'border-2 border-blue-500' : ''}`}>
      <div className="flex justify-between items-start">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onSelect(event.id)}
          className="form-checkbox h-5 w-5 text-blue-600"
        />
      </div>
      <div className="flex-grow flex flex-col">
        <h3 className="text-lg font-semibold mb-2">{event.name || 'Unnamed Event'}</h3>
        <p className="text-gray-600 mb-2">Client: {event.clientName || 'N/A'}</p>
        <p className="text-sm text-gray-500 mb-2">Date: {event.date || 'TBD'}</p>
        <p className="text-sm text-gray-500 mb-2">Location: {event.location || 'TBD'}</p>
        
        <div className="mb-4">
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
                  {statusObj.name}
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-teal-600">
                  {((status / (statuses.length - 1)) * 100).toFixed(0)}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-teal-200">
              <div 
                style={{ width: `${(status / (statuses.length - 1)) * 100}%` }} 
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <button onClick={() => onViewDetails(event)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 text-sm">
          View Details
        </button>
        <Link to={`/edit-event/${event.id}`} className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 text-sm">
          Edit
        </Link>
      </div>
    </div>
  );
};

const EventDetailsModal = ({ isOpen, onClose, event, onStatusUpdate }) => {
  const [client, setClient] = useState(null);
  const [supervisor, setSupervisor] = useState(null);
  const [products, setProducts] = useState([]);
  const [equipment, setEquipment] = useState([]);

  useEffect(() => {
    const fetchRelatedData = async () => {
      if (event) {
        // Fetch client
        if (event.clientId) {
          const clientRef = ref(db, `clients/${event.clientId}`);
          const clientSnapshot = await get(clientRef);
          if (clientSnapshot.exists()) {
            setClient(clientSnapshot.val());
          }
        }

        // Fetch supervisor
        if (event.eventSupervisorId) {
          const supervisorRef = ref(db, `supervisors/${event.eventSupervisorId}`);
          const supervisorSnapshot = await get(supervisorRef);
          if (supervisorSnapshot.exists()) {
            setSupervisor(supervisorSnapshot.val());
          }
        }

        // Fetch products
        if (event.products && event.products.length > 0) {
          const productsRef = ref(db, 'products');
          const productsSnapshot = await get(productsRef);
          if (productsSnapshot.exists()) {
            const allProducts = productsSnapshot.val();
            const eventProducts = event.products.map(id => allProducts[id]).filter(Boolean);
            setProducts(eventProducts);
          }
        }

        // Fetch equipment
        if (event.equipment && event.equipment.length > 0) {
          const equipmentRef = ref(db, 'equipments');
          const equipmentSnapshot = await get(equipmentRef);
          if (equipmentSnapshot.exists()) {
            const allEquipment = equipmentSnapshot.val();
            const eventEquipment = event.equipment.map(id => allEquipment[id]).filter(Boolean);
            setEquipment(eventEquipment);
          }
        }
      }
    };

    fetchRelatedData();
  }, [event]);

  if (!event) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-2xl font-bold mb-4">{event.name}</h2>
      <div className="grid grid-cols-1 gap-4">
        <p><strong>Client:</strong> {client ? client.name : 'N/A'}</p>
        <p><strong>Date:</strong> {event.date}</p>
        <p><strong>Location:</strong> {event.location}</p>
        <p><strong>Expected Attendees:</strong> {event.expectedAttendees}</p>
        <p><strong>Staff Count:</strong> {event.staffCount}</p>
        
        {supervisor && (
          <p><strong>Event Supervisor:</strong> {supervisor.name}</p>
        )}
        
        <div>
          <strong>Status:</strong>
          <select
            value={event.status}
            onChange={(e) => onStatusUpdate(event.id, Number(e.target.value))}
            className="ml-2 border rounded px-2 py-1"
          >
            {statuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Current Status: {statuses[event.status].name}</h3>
          <p className="text-gray-600 mb-2">{statuses[event.status].description}</p>
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
                  Progress
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-teal-600">
                  {((event.status / (statuses.length - 1)) * 100).toFixed(0)}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-teal-200">
              <div 
                style={{ width: `${(event.status / (statuses.length - 1)) * 100}%` }} 
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"
              ></div>
            </div>
          </div>
        </div>

        {products.length > 0 && (
          <div>
            <strong>Products:</strong>
            <ul className="list-disc list-inside">
              {products.map(product => (
                <li key={product.id}>{product.name}</li>
              ))}
            </ul>
          </div>
        )}

        {equipment.length > 0 && (
          <div>
            <strong>Equipment:</strong>
            <ul className="list-disc list-inside">
              {equipment.map(item => (
                <li key={item.id}>{item.name}</li>
              ))}
            </ul>
          </div>
        )}

        {event.images && event.images.length > 0 && (
          <div>
            <strong>Event Images:</strong>
            <div className="grid grid-cols-3 gap-2 mt-2">
              {event.images.map((image, index) => (
                <img key={index} src={image} alt={`Event ${index + 1}`} className="w-full h-24 object-cover rounded" />
              ))}
            </div>
          </div>
        )}

        <div>
          <strong>Description:</strong>
          <p className="mt-1">{event.description}</p>
        </div>
      </div>
    </Modal>
  );
};

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('date');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filterStatus, setFilterStatus] = useState('');
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 10;

  const fetchEvents = async () => {
    setLoading(true);
    setError(null);
    const eventsRef = ref(db, 'events');
    const eventsQuery = query(eventsRef, orderByChild(sortField));
  
    try {
      const snapshot = await get(eventsQuery);
      if (snapshot.exists()) {
        const eventList = await Promise.all(
          Object.entries(snapshot.val()).map(async ([id, eventData]) => {
            let clientName = 'N/A';
            if (eventData.clientId) {
              const clientSnapshot = await get(ref(db, `clients/${eventData.clientId}`));
              if (clientSnapshot.exists()) {
                clientName = clientSnapshot.val().name;
              }
            }
            return {
              id,
              ...eventData,
              clientName
            };
          })
        );
        setEvents(eventList);
      } else {
        setEvents([]);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setError("Failed to fetch events. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [sortField]);

  const filteredAndSortedEvents = useMemo(() => {
    return events
      .filter(event =>
        (event.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
         event.location.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (filterStatus === '' || event.status === parseInt(filterStatus))
      )
      .sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [events, searchTerm, filterStatus, sortField, sortDirection]);

  const paginatedEvents = useMemo(() => {
    const startIndex = (currentPage - 1) * eventsPerPage;
    return filteredAndSortedEvents.slice(startIndex, startIndex + eventsPerPage);
  }, [filteredAndSortedEvents, currentPage]);

  const totalPages = Math.ceil(filteredAndSortedEvents.length / eventsPerPage);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (field) => {
    setSortDirection(field === sortField && sortDirection === 'asc' ? 'desc' : 'asc');
    setSortField(field);
    setCurrentPage(1);
  };

  const handleStatusFilter = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedEvents(paginatedEvents.map(event => event.id));
    } else {
      setSelectedEvents([]);
    }
  };

  const handleSelectEvent = (eventId) => {
    setSelectedEvents(prevSelected => 
      prevSelected.includes(eventId)
        ? prevSelected.filter(id => id !== eventId)
        : [...prevSelected, eventId]
    );
  };

  const handleDeleteSelected = async () => {
    if (window.confirm(`Are you sure you want to delete ${selectedEvents.length} selected events? This action cannot be undone.`)) {
      setIsDeleting(true);
      try {
        for (let eventId of selectedEvents) {
          await remove(ref(db, `events/${eventId}`));
        }
        await fetchEvents();
        setSelectedEvents([]);
      } catch (error) {
        console.error("Error deleting events:", error);
        setError("Failed to delete selected events. Please try again.");
      }
      setIsDeleting(false);
    }
  };

  const handleViewDetails = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  const handleStatusUpdate = async (eventId, newStatus) => {
    try {
      const eventRef = ref(db, `events/${eventId}`);
      await update(eventRef, { status: newStatus });
      setEvents(prevEvents => prevEvents.map(event => 
        event.id === eventId ? { ...event, status: newStatus } : event
      ));
      if (selectedEvent && selectedEvent.id === eventId) {
        setSelectedEvent({ ...selectedEvent, status: newStatus });
      }
    } catch (error) {
      console.error("Error updating event status:", error);
      setError("Failed to update event status. Please try again.");
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Events</h1>
        <Link to="/add-event" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
          Add Event
        </Link>
      </div>
      
      <div className="mb-6 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
        <input
          type="text"
          placeholder="Search events..."
          value={searchTerm}
          onChange={handleSearch}
          className="border rounded px-4 py-2 w-full sm:w-64"
        />
        <select
          value={sortField}
          onChange={(e) => handleSort(e.target.value)}
          className="border rounded px-4 py-2 w-full sm:w-auto"
        >
          <option value="date">Sort by Date</option>
          <option value="name">Sort by Name</option>
          <option value="location">Sort by Location</option>
        </select>
        <select
          value={filterStatus}
          onChange={handleStatusFilter}
          className="border rounded px-4 py-2 w-full sm:w-auto"
        >
          <option value="">All Statuses</option>
          {statuses.map((status) => (
            <option key={status.id} value={status.id}>
              {status.name}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          id="selectAll"
          checked={selectedEvents.length === paginatedEvents.length && paginatedEvents.length > 0}
          onChange={handleSelectAll}
          className="form-checkbox h-5 w-5 text-blue-600"
        />
        <label htmlFor="selectAll" className="ml-2">Select All</label>
        {selectedEvents.length > 0 && (
          <button
            onClick={handleDeleteSelected}
            className="ml-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:bg-red-300"
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : `Delete Selected (${selectedEvents.length})`}
          </button>
        )}
      </div>

      {error ? (
        <div className="text-red-500 text-center my-8">
          <p>{error}</p>
          <button 
            onClick={fetchEvents} 
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      ) : loading ? (
        <p className="text-center mt-4">Loading...</p>
      ) : paginatedEvents.length === 0 ? (
        <p className="text-center text-gray-500 my-8">No events found.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {paginatedEvents.map(event => (
            <EventCard
              key={event.id}
              event={event}
              isSelected={selectedEvents.includes(event.id)}
              onSelect={handleSelectEvent}
              onViewDetails={handleViewDetails}
            />
          ))}
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex justify-center mt-8">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || loading}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                disabled={loading}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                  currentPage === index + 1 ? 'text-blue-600 bg-blue-50' : 'text-gray-700 hover:bg-gray-50'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages || loading}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              Next
            </button>
          </nav>
        </div>
      )}

      <EventDetailsModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        event={selectedEvent}
        onStatusUpdate={handleStatusUpdate}
      />
    </div>
  );
};

export default Events;