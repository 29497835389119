import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="bg-gray-800 text-white w-64 min-h-screen p-4">
      <h1 className="text-2xl font-bold mb-4">Event Manager</h1>
      <nav>
        <ul>
          <li className="mb-2">
            <Link to="/dashboard" className="block p-2 hover:bg-gray-700 rounded">Dashboard</Link>
          </li>
          {/* <li className="mb-2">
            <Link to="/ai" className="block p-2 hover:bg-gray-700 rounded">Event AI Bot Interaction</Link>
          </li> */}
          <li className="mb-2">
            <Link to="/clients" className="block p-2 hover:bg-gray-700 rounded">Clients</Link>
          </li>
          <li className="mb-2">
            <Link to="/supervisors" className="block p-2 hover:bg-gray-700 rounded">Event Supervisors</Link>
          </li>
          <li className="mb-2">
            <Link to="/events" className="block p-2 hover:bg-gray-700 rounded">Events</Link>
          </li>
          <li className="mb-2">
            <Link to="/products" className="block p-2 hover:bg-gray-700 rounded">Products</Link>
          </li>
          <li className="mb-2">
            <Link to="/equipments" className="block p-2 hover:bg-gray-700 rounded">Equipments</Link>
          </li>
          <li className="mb-2">
            <Link to="/reports" className="block p-2 hover:bg-gray-700 rounded">Reports</Link>
          </li>
          <li className="mb-2">
            <Link to="/notification" className="block p-2 hover:bg-gray-700 rounded">Send Notification</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;