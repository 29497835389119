import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ref, get, update } from 'firebase/database';
import { db } from '../firebase';

const EditSupervisor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [supervisor, setSupervisor] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    dateOfBirth: '',
    hireDate: '',
    certifications: '',
    emergencyContact: '',
    notes: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSupervisor = async () => {
      try {
        const supervisorRef = ref(db, `supervisors/${id}`);
        const snapshot = await get(supervisorRef);
        if (snapshot.exists()) {
          setSupervisor(snapshot.val());
        } else {
          setError("Supervisor not found");
        }
      } catch (error) {
        console.error("Error fetching supervisor:", error);
        setError("Failed to fetch supervisor details");
      } finally {
        setLoading(false);
      }
    };

    fetchSupervisor();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupervisor(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const supervisorRef = ref(db, `supervisors/${id}`);
      await update(supervisorRef, supervisor);
      setLoading(false);
      navigate('/supervisors');
    } catch (error) {
      console.error("Error updating supervisor:", error);
      setError("Failed to update supervisor. Please try again.");
      setLoading(false);
    }
  };

  if (loading) return <p>Loading supervisor details...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Edit Supervisor</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block mb-1">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={supervisor.name}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="email" className="block mb-1">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={supervisor.email}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="phone" className="block mb-1">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={supervisor.phone}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="address" className="block mb-1">Address</label>
          <input
            type="text"
            id="address"
            name="address"
            value={supervisor.address}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="dateOfBirth" className="block mb-1">Date of Birth</label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={supervisor.dateOfBirth}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="hireDate" className="block mb-1">Hire Date</label>
          <input
            type="date"
            id="hireDate"
            name="hireDate"
            value={supervisor.hireDate}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="certifications" className="block mb-1">Certifications</label>
          <input
            type="text"
            id="certifications"
            name="certifications"
            value={supervisor.certifications}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="emergencyContact" className="block mb-1">Emergency Contact</label>
          <input
            type="text"
            id="emergencyContact"
            name="emergencyContact"
            value={supervisor.emergencyContact}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="notes" className="block mb-1">Notes</label>
          <textarea
            id="notes"
            name="notes"
            value={supervisor.notes}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
            rows="3"
          />
        </div>
        <button
  type="submit"
  disabled={loading}
  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
>
  {loading ? 'Updating...' : 'Update Supervisor'}
</button>
      </form>
    </div>
  );
};

export default EditSupervisor;