import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCSkTX7cVifoHHr6IFqlUA8nufMnmkxLDQ",
  authDomain: "event-management-app-3507f.firebaseapp.com",
  projectId: "event-management-app-3507f",
  storageBucket: "event-management-app-3507f.appspot.com",
  messagingSenderId: "216360872094",
  appId: "1:216360872094:web:14dcbd7c03e3156136f38f",
  databaseURL: "https://event-management-app-3507f-default-rtdb.firebaseio.com",
  measurementId: "G-4SDHPPDYSE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// const analytics = getAnalytics(app);
export { 
  db, 
  auth, 
  storage, 
  functions,
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut 
};