import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ref, get, update, remove } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';

const statuses = [
  { id: 0, name: 'Planning', description: 'Initial planning and preparation' },
  { id: 1, name: 'Booking', description: 'Booking venues and services' },
  { id: 2, name: 'Invitations', description: 'Sending out invitations' },
  { id: 3, name: 'Preparations', description: 'Final preparations' },
  { id: 4, name: 'In Progress', description: 'Event is currently happening' },
  { id: 5, name: 'Completed', description: 'Event has concluded' }
];

const EditEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState({
    name: '',
    clientId: '',
    date: '',
    status: 0,
    location: '',
    expectedAttendees: '',
    description: '',
    products: [],
    equipment: [],
    eventSupervisorId: '',
    staffCount: 0,
    images: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [clients, setClients] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [productSearch, setProductSearch] = useState('');
  const [equipmentSearch, setEquipmentSearch] = useState('');
  const [productResults, setProductResults] = useState([]);
  const [equipmentResults, setEquipmentResults] = useState([]);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventRef = ref(db, `events/${id}`);
        const snapshot = await get(eventRef);
        if (snapshot.exists()) {
          const eventData = snapshot.val();
          
          // Fetch full product details
          const productPromises = (eventData.products || []).map(async (productId) => {
            const productRef = ref(db, `products/${productId}`);
            const productSnapshot = await get(productRef);
            return productSnapshot.exists() ? { id: productId, ...productSnapshot.val() } : null;
          });
          const products = (await Promise.all(productPromises)).filter(Boolean);

          // Fetch full equipment details
          const equipmentPromises = (eventData.equipment || []).map(async (equipmentId) => {
            const equipmentRef = ref(db, `equipments/${equipmentId}`);
            const equipmentSnapshot = await get(equipmentRef);
            return equipmentSnapshot.exists() ? { id: equipmentId, ...equipmentSnapshot.val() } : null;
          });
          const equipment = (await Promise.all(equipmentPromises)).filter(Boolean);

          setEvent({
            id,
            ...eventData,
            products,
            equipment,
            images: Array.isArray(eventData.images) ? eventData.images : []
          });
        } else {
          setError("Event not found");
        }
      } catch (error) {
        console.error("Error fetching event:", error);
        setError("Failed to fetch event details");
      } finally {
        setLoading(false);
      }
    };

    const fetchClients = async () => {
      const clientsRef = ref(db, 'clients');
      const snapshot = await get(clientsRef);
      if (snapshot.exists()) {
        const clientsData = snapshot.val();
        const clientsList = Object.keys(clientsData).map(key => ({
          id: key,
          ...clientsData[key]
        }));
        setClients(clientsList);
      }
    };

    const fetchSupervisors = async () => {
      const supervisorsRef = ref(db, 'supervisors');
      const snapshot = await get(supervisorsRef);
      if (snapshot.exists()) {
        const supervisorsData = snapshot.val();
        const supervisorsList = Object.keys(supervisorsData).map(key => ({
          id: key,
          ...supervisorsData[key]
        }));
        setSupervisors(supervisorsList);
      }
    };

    fetchEvent();
    fetchClients();
    fetchSupervisors();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent(prevEvent => ({
      ...prevEvent,
      [name]: name === 'status' || name === 'staffCount' || name === 'expectedAttendees' 
        ? parseInt(value, 10) 
        : value
    }));
  };

  const handleProductSearch = async (e) => {
    setProductSearch(e.target.value);
    if (e.target.value.length > 2) {
      const productsRef = ref(db, 'products');
      const snapshot = await get(productsRef);
      if (snapshot.exists()) {
        const allProducts = Object.entries(snapshot.val()).map(([id, data]) => ({ id, ...data }));
        const filtered = allProducts.filter(product => 
          product.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setProductResults(filtered);
      }
    } else {
      setProductResults([]);
    }
  };

  const handleEquipmentSearch = async (e) => {
    setEquipmentSearch(e.target.value);
    if (e.target.value.length > 2) {
      const equipmentRef = ref(db, 'equipments');
      const snapshot = await get(equipmentRef);
      if (snapshot.exists()) {
        const allEquipment = Object.entries(snapshot.val()).map(([id, data]) => ({ id, ...data }));
        const filtered = allEquipment.filter(equipment => 
          equipment.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setEquipmentResults(filtered);
      }
    } else {
      setEquipmentResults([]);
    }
  };

  const addProduct = (product) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      products: [...prevEvent.products, product]
    }));
    setProductSearch('');
    setProductResults([]);
  };

  const addEquipment = (equipment) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      equipment: [...prevEvent.equipment, equipment]
    }));
    setEquipmentSearch('');
    setEquipmentResults([]);
  };

  const removeProduct = (productId) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      products: prevEvent.products.filter(p => p.id !== productId)
    }));
  };

  const removeEquipment = (equipmentId) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      equipment: prevEvent.equipment.filter(e => e.id !== equipmentId)
    }));
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const newImages = [];
  
    for (let file of files) {
      const imageRef = storageRef(storage, `events/${id}/${file.name}`);
      try {
        const snapshot = await uploadBytes(imageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        newImages.push(downloadURL);
      } catch (error) {
        console.error("Error uploading image:", error);
        setError("Failed to upload image. Please try again.");
      }
    }
  
    setEvent(prevEvent => ({
      ...prevEvent,
      images: [...prevEvent.images, ...newImages]
    }));
  };

  const removeImage = (index) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      images: prevEvent.images.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const eventRef = ref(db, `events/${id}`);
      const updatedEvent = {
        ...event,
        products: event.products.map(p => p.id),
        equipment: event.equipment.map(e => e.id),
      };
      await update(eventRef, updatedEvent);
      setLoading(false);
      navigate('/events');
    } catch (error) {
      console.error("Error updating event:", error);
      setError("Failed to update event. Please try again.");
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this event? This action cannot be undone.")) {
      setLoading(true);
      try {
        const eventRef = ref(db, `events/${id}`);
        await remove(eventRef);
        navigate('/events');
      } catch (error) {
        console.error("Error deleting event:", error);
        setError("Failed to delete event. Please try again.");
        setLoading(false);
      }
    }
  };

  if (loading) return <p className="text-center mt-4">Loading...</p>;
  if (error) return <p className="text-center text-red-500 mt-4">{error}</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Edit Event</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="name" className="block mb-2">Event Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={event.name}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label htmlFor="clientId" className="block mb-2">Client</label>
            <select
              id="clientId"
              name="clientId"
              value={event.clientId}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            >
              <option value="">Select a client</option>
              {clients.map(client => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="date" className="block mb-2">Event Date</label>
            <input
              type="date"
              id="date"
              name="date"
              value={event.date}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label htmlFor="status" className="block mb-2">Status</label>
            <select
              id="status"
              name="status"
              value={event.status}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            >
              {statuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="location" className="block mb-2">Location</label>
            <input
              type="text"
              id="location"
              name="location"
              value={event.location}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label htmlFor="expectedAttendees" className="block mb-2">Expected Attendees</label>
            <input
              type="number"
              id="expectedAttendees"
              name="expectedAttendees"
              value={event.expectedAttendees}
              onChange={handleChange}
              required
              min="0"
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label htmlFor="eventSupervisorId" className="block mb-2">Event Supervisor</label>
            <select
              id="eventSupervisorId"
              name="eventSupervisorId"
              value={event.eventSupervisorId}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            >
              <option value="">Select a supervisor</option>
              {supervisors.map((supervisor) => (
                <option key={supervisor.id} value={supervisor.id}>
                  {supervisor.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="staffCount" className="block mb-2">Staff Count</label>
            <input
              type="number"
              id="staffCount"
              name="staffCount"
              value={event.staffCount}
              onChange={handleChange}
              required
              min="0"
              className="w-full px-3 py-2 border rounded"
            />
          </div>
        </div>
        
        <div>
          <label htmlFor="description" className="block mb-2">Description</label>
          <textarea
            id="description"
            name="description"
            value={event.description}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
            rows="4"
          />
        </div>

        <div>
          <label htmlFor="productSearch" className="block mb-2">Add Products</label>
          <input
            type="text"
            id="productSearch"
            value={productSearch}
            onChange={handleProductSearch}
            placeholder="Search for products..."
            className="w-full px-3 py-2 border rounded mb-2"
          />
          {productResults.length > 0 && (
            <ul className="border rounded mt-2 max-h-40 overflow-y-auto">
              {productResults.map(product => (
                <li 
                  key={product.id} 
                  className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => addProduct(product)}
                >
                  {product.name}
                </li>
              ))}
            </ul>
          )}
          {event.products.length > 0 && (
            <div className="mt-2">
              <h4 className="font-semibold">Selected Products:</h4>
              <ul>
                {event.products.map(product => (
                  <li key={product.id} className="flex justify-between items-center py-1">
                    <span>{product.name}</span>
                    <button 
                      type="button"
                      onClick={() => removeProduct(product.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          </div>

<div>
  <label htmlFor="equipmentSearch" className="block mb-2">Add Equipment</label>
  <input
    type="text"
    id="equipmentSearch"
    value={equipmentSearch}
    onChange={handleEquipmentSearch}
    placeholder="Search for equipment..."
    className="w-full px-3 py-2 border rounded mb-2"
  />
  {equipmentResults.length > 0 && (
    <ul className="border rounded mt-2 max-h-40 overflow-y-auto">
      {equipmentResults.map(equipment => (
        <li 
          key={equipment.id} 
          className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
          onClick={() => addEquipment(equipment)}
        >
          {equipment.name}
        </li>
      ))}
    </ul>
  )}
  {event.equipment.length > 0 && (
    <div className="mt-2">
      <h4 className="font-semibold">Selected Equipment:</h4>
      <ul>
        {event.equipment.map(equipment => (
          <li key={equipment.id} className="flex justify-between items-center py-1">
            <span>{equipment.name}</span>
            <button 
              type="button"
              onClick={() => removeEquipment(equipment.id)}
              className="text-red-500 hover:text-red-700"
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
    </div>
  )}
</div>

<div>
<label htmlFor="images" className="block mb-2">Event Images</label>
          <input
            type="file"
            id="images"
            multiple
            accept="image/*"
            onChange={handleImageUpload}
            className="w-full px-3 py-2 border rounded"
          />
          {event.images && event.images.length > 0 && (
            <div className="mt-4 grid grid-cols-3 gap-4">
              {event.images.map((image, index) => (
                <div key={index} className="relative">
                  <img 
                    src={image} 
                    alt={`Event ${index + 1}`} 
                    className="w-full h-32 object-cover rounded" 
                    onError={(e) => {
                      e.target.onerror = null; 
                      e.target.src = 'https://via.placeholder.com/150?text=Image+Not+Found';
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

<div className="mb-4">
  <h3 className="text-lg font-semibold mb-2">Current Status: {statuses[event.status].name}</h3>
  <p className="text-gray-600 mb-2">{statuses[event.status].description}</p>
  <div className="relative pt-1">
    <div className="flex mb-2 items-center justify-between">
      <div>
        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
          Progress
        </span>
      </div>
      <div className="text-right">
        <span className="text-xs font-semibold inline-block text-teal-600">
          {((event.status / (statuses.length - 1)) * 100).toFixed(0)}%
        </span>
      </div>
    </div>
    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-teal-200">
      <div 
        style={{ width: `${(event.status / (statuses.length - 1)) * 100}%` }} 
        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"
      ></div>
    </div>
  </div>
</div>

<div className="flex justify-between">
  <button 
    type="submit" 
    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
    disabled={loading}
  >
    {loading ? 'Updating Event...' : 'Update Event'}
  </button>
  <button 
    type="button" 
    onClick={handleDelete}
    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:bg-red-300"
    disabled={loading}
  >
    {loading ? 'Deleting...' : 'Delete Event'}
  </button>
</div>
</form>
</div>
);
};

export default EditEvent;