import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ref, get, update, remove } from 'firebase/database';
import { db } from '../firebase';

const EditProduct = () => {
  const [product, setProduct] = useState({
    name: '',
    price: '',
    description: '',
    imageUrl: '',
    category: '',
    stock: '',
    sku: '',
    weight: '',
    dimensions: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productRef = ref(db, `products/${id}`);
        const snapshot = await get(productRef);
        if (snapshot.exists()) {
          setProduct({ id, ...snapshot.val() });
        } else {
          setError("Product not found");
          navigate('/products');
        }
      } catch (error) {
        console.error("Error fetching product:", error);
        setError("Failed to fetch product details");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct(prevProduct => ({ ...prevProduct, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const productRef = ref(db, `products/${id}`);
      await update(productRef, {
        ...product,
        price: parseFloat(product.price),
        stock: parseInt(product.stock, 10),
        weight: parseFloat(product.weight),
      });
      navigate('/products');
    } catch (error) {
      console.error("Error updating product: ", error);
      setError("Failed to update product. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this product? This action cannot be undone.")) {
      setIsDeleting(true);
      setError(null);

      try {
        const productRef = ref(db, `products/${id}`);
        await remove(productRef);
        navigate('/products');
      } catch (error) {
        console.error("Error deleting product: ", error);
        setError("Failed to delete product. Please try again.");
        setIsDeleting(false);
      }
    }
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Edit Product</h1>
      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={product.name}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="price" className="block mb-2">Price</label>
            <input
              type="number"
              id="price"
              name="price"
              value={product.price}
              onChange={handleChange}
              required
              step="0.01"
              min="0"
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="imageUrl" className="block mb-2">Image URL</label>
            <input
              type="url"
              id="imageUrl"
              name="imageUrl"
              value={product.imageUrl}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="category" className="block mb-2">Category</label>
            <select
              id="category"
              name="category"
              value={product.category}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            >
              <option value="">Select a category</option>
              <option value="Whiskey">Whiskey</option>
              <option value="Vodka">Vodka</option>
              <option value="Gin">Gin</option>
              <option value="Rum">Rum</option>
              <option value="Tequila">Tequila</option>
              <option value="Wine">Wine</option>
              <option value="Beer">Beer</option>
              <option value="Liqueurs">Liqueurs</option>
              <option value="Brandy">Brandy</option>
              <option value="Champagne">Champagne</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="stock" className="block mb-2">Stock</label>
            <input
              type="number"
              id="stock"
              name="stock"
              value={product.stock}
              onChange={handleChange}
              required
              min="0"
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="sku" className="block mb-2">SKU</label>
            <input
              type="text"
              id="sku"
              name="sku"
              value={product.sku}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="weight" className="block mb-2">Weight (kg)</label>
            <input
              type="number"
              id="weight"
              name="weight"
              value={product.weight}
              onChange={handleChange}
              step="0.01"
              min="0"
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="dimensions" className="block mb-2">Dimensions (L x W x H cm)</label>
            <input
              type="text"
              id="dimensions"
              name="dimensions"
              value={product.dimensions}
              onChange={handleChange}
              placeholder="e.g., 10 x 5 x 2"
              className="w-full px-3 py-2 border rounded"
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block mb-2">Description</label>
          <textarea
            id="description"
            name="description"
            value={product.description}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
            rows="4"
          />
        </div>
        <div className="flex justify-between">
          <button 
            type="submit" 
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
            disabled={loading || isDeleting}
          >
            {loading ? 'Updating Product...' : 'Update Product'}
          </button>
          <button 
            type="button" 
            onClick={handleDelete}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:bg-red-300"
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete Product'}
          </button>
          <button 
            type="button" 
            onClick={() => navigate('/products')}
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
            disabled={loading || isDeleting}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProduct;