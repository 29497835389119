import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { db } from '../firebase';

const ViewClient = () => {
  const { id } = useParams();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const clientRef = ref(db, `clients/${id}`);
        const snapshot = await get(clientRef);
        if (snapshot.exists()) {
          setClient({ id, ...snapshot.val() });
        } else {
          setError("Client not found");
        }
      } catch (error) {
        console.error("Error fetching client:", error);
        setError("Failed to fetch client details");
      } finally {
        setLoading(false);
      }
    };

    fetchClient();
  }, [id]);

  if (loading) return <p>Loading client details...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!client) return <p>No client data available.</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Client Details</h1>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-2">{client.name}</h2>
          <p><strong>Email:</strong> {client.email}</p>
          <p><strong>Phone:</strong> {client.phone}</p>
          <p><strong>Company:</strong> {client.company || 'N/A'}</p>
          <p><strong>Address:</strong> {client.address || 'N/A'}</p>
          <div className="mt-4">
            <h3 className="text-xl font-semibold mb-2">Notes</h3>
            <p>{client.notes || 'No notes available.'}</p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Link to={`/edit-client/${id}`} className="bg-yellow-500 text-white px-4 py-2 rounded mr-2">
          Edit
        </Link>
        <Link to="/clients" className="bg-gray-500 text-white px-4 py-2 rounded">
          Back to List
        </Link>
      </div>
    </div>
  );
};

export default ViewClient;